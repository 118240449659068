<template>
  <div class="card-maid d-flex flex-column justify-content-between" @click="viewMaidDetail">
    <div v-if="!isLoading && !isPrerender">
      <div class="fav-icon" v-if="hasFavIcon">
        <i
          class="fa-heart m-2"
          :class="{ far: !isFavoriteMaid, ['fas text-red']: isFavoriteMaid }"
          @click.stop="toggleFavMaid"
        ></i>
      </div>
      <div>
        <!-- imagecover -->
        <!-- <b-img :src="imageCover" class="d-none d-md-block cover-image"></b-img> -->
        <b-img
          alt="Maid Cover"
          :src="maid_cover"
          class="d-none d-md-block cover-image"
        ></b-img>
        <div
          class="maid-detail maid-detail-top mb-0 pb-0"
        >
          <div class="maid-detail-img">
            <MaidAvatar
              :url="imgURL"
              :level="detail.level"
              size="md"
              v-if="!recommendRating"
              @click="viewMaidDetail"
            />
            <div class="d-flex mr-2" v-else @click="viewMaidDetail">
              <b-img
                :src="getImg(friend.image_url)"
                v-for="friend in friendsList"
                alt="friend avatar"
                :key="friend.id"
                class="friend_avatar"
              ></b-img>
              <b-img
                :src="require('../assets/images/preview-upload.png')"
                class="friend_avatar"
                v-if="friendsList.length === 0"
                alt="friend avatar"
              ></b-img>
              <div class="more-friend d-inline-block" v-if="friendsList.length === 0">
                <span>+0</span>
              </div>
              <div class="more-friend d-inline-block" v-if="friendsList.length > 3">
                <span>+ {{ friendsList.length - 3 }}</span>
              </div>
            </div>
          </div>
          <div class="maid-detail-info overflow-hidden">
            <div class="name" v-if="!recommendRating">
              <div class="d-none d-md-block">
                <span
                  >{{ detail.user.nick_name ? `"${detail.user.nick_name}"` : `` }}
                  {{ detail.user.first_name }} {{ detail.user.last_name }}</span
                >
              </div>
              <span class="d-inline-block d-md-none">
                {{ detail.user.nick_name ? `"${detail.user.nick_name}"` : `` }}
                {{ `${detail.user.first_name} ${detail.user.last_name}` }}
              </span>
            </div>
            <div class="rating d-flex justify-content-start flex-wrap align-items-center">
              <div class="mr-1 text-nowrap">
                {{ detail.rating ? parseFloat(detail.rating).toFixed(1) : $t('alias.no_rating') }}
              </div>
              <star-rating
                :read-only="true"
                :rating="starRating"
                :max-rating="1"
                :round-start-rating="true"
                :show-rating="false"
                :star-size="16"
                class="mt-n1"
              >
              </star-rating>
              <div class="ml-1 mr-2  text-nowrap">
                {{ detail.no_of_reviews ? detail.no_of_reviews : 0 }} {{ $t('alias.reviews') }}
              </div>
              <div class="flex-nowrap d-flex align-items-center" v-b-tooltip.hover @click.stop :title="$t('form_label.repeat_rate')">
                <img src="./../assets/images/heart-icon-jst.png" alt="heart icon" width="16px" height="16px"/>
                &nbsp;{{ repeatRate }} %
                <br />
              </div>
            </div>
          </div>
        </div>
        <div class="maid-detail maid-detail-top mb-0 pt-0 pb-0">
          <div class="maid-detail-img vaccine">
            <img v-if="detail.vaccinated" src="./../assets/images/vaccine.svg" class="vaccine" alt="" style="margin-top: 6px" />
            <div class="d-flex mr-2">
            </div>
          </div>
          <div class="maid-detail-info w-100 overflow-hidden mb-0">
            <div class="d-flex">
              <div v-if="detail.availability_status == 'LIMITED_AVAILABILITY'" class="limited">
                {{ this.$t('modal.maid_detail.limited_availability') }}
              </div>
              <div  v-else-if="detail.availability_status == 'LOT_OF_AVAILABILITY'" class="a_lot">
                {{ this.$t('modal.maid_detail.a_lot_of_availability') }}
              </div>
            </div>
          </div>
        </div>

        <div class="maid-detail maid-detail-bottom">
          <b-card-text class="w-100">
            <!-- <div v-show="!selectMaidPage">
              <span>{{ $t('modal.maid_detail.repeat_rate') }}:</span>
              {{ repeatRate }} %
              <br />
              <span>{{ $t('modal.maid_detail.work_completed') }}:</span>
              {{ detail.total_works }} {{ $t('alias.times') }}
            </div> -->
            <div v-if="detail.description">
              <hr />
              <p class="maid-desc">
                {{ detail.description }}
              </p>
            </div>
          </b-card-text>
        </div>
      </div>
    </div>
    <div v-else>
      <vue-content-loading :width="200" :height="180">
        <rect x="0" y="0" rx="4" ry="4" width="200" height="100" />
        <circle cx="40" cy="150" r="25" />
        <rect x="75" y="130" rx="4" ry="4" width="80" height="8" />
        <rect x="75" y="145" rx="4" ry="4" width="70" height="8" />
        <rect x="75" y="160" rx="4" ry="4" width="60" height="8" />
      </vue-content-loading>
    </div>

    <b-button
      block
      slot="footer"
      :variant="variantBtn"
      size="mg"
      v-if="hasButton"
      @click.stop="selectMaid"
      >{{ $t('button.choose_this_maid') }}</b-button
    >
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import StarRating from 'vue-star-rating'
import { createNamespacedHelpers } from 'vuex'
import VueContentLoading from 'vue-content-loading'
import MaidAvatar from './MaidAvatar'
import avatarDefault from '@/assets/images/avatar_default.jpg'
// import maidCover from '@/assets/images/maid-cover-1.jpg'

const MaidStore = createNamespacedHelpers('maid')
const BookingStore = createNamespacedHelpers('booking')

@Component({
  components: { StarRating, VueContentLoading, MaidAvatar },
  props: {
    hasButton: {
      type: Boolean,
      default: false,
    },
    detail: Object,
    selectType: String,
    selectedTime: Object,
    selectedDate: {
      type: String,
      default: '',
    },
    hasFavIcon: {
      type: Boolean,
      default: false,
    },
    recommendRating: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disableClick: {
      type: Boolean,
      default: false,
    },
    isFavoriteMaid: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    ...MaidStore.mapActions(['setShowMaidModal', 'unSaveToFavMaid', 'saveToFavMaid']),
    ...BookingStore.mapActions(['setMaidForBooking']),
    ...MaidStore.mapMutations(['addRecentlyUnFavMaids', 'updateStates']),
  },
})
export default class Maid extends Vue {
  // maid_cover = maidCover
  maid_cover = 'https://dev.houseofdev.tech/assets/images/maid-cover-1.jpg'

  get starRating() {
    return parseInt(this.detail.rating) > 0 ? 1 : 0
  }

  get selectMaidPage() {
    console.log('window page>>>', window.location)
    return window.location.pathname.includes('/select-maid')
  }

  get variantBtn() {
    if (this.selectType === 'one-time') {
      return 'success'
    } else if (this.selectType === 'multi-package') {
      return 'warning'
    } else {
      return ''
    }
  }

  get imageCover() {
    const hasCover = this.detail.cover_images && this.detail.cover_images.length > 0
    return hasCover ? this.detail.cover_images[0] : require(`../assets/images/maid-cover-1.jpg`)
  }

  get repeatRate() {
    const rate = Math.ceil(this.detail.repeat_rate)
    return rate > 0 ? rate : 0
  }

  get friendsList() {
    return this.detail ? this.detail.friends : []
  }

  get maidID() {
    return this.detail.id
  }
  get imgURL() {
    return this.detail
      ? this.detail.user.image_url
      : avatarDefault
  }

  isPrerender = ( window.__prerender && window.__prerender.prerendering ) || false

  // This is an anit-pattern
  // Should fixed by emit maid object and handle/commit by Page component
  async selectMaid() {
    await this.$store.commit('calendar/resetMaidCalendar')
    const route = this.$route
    this.setMaidForBooking({ type: this.selectType, maid: { ...this.detail, selected_time: this.selectedTime, selected_date: this.selectedDate }})
    switch (this.selectType) {
      case 'one-time':
        this.$store.commit('booking/setPreventFetchBooking', true)
        route.query.callback
          ? this.$router.push({
              path: route.query.callback,
              query: { load: false, from: route.query.from },
            })
          : this.$router.push({ name: 'BookingOneTime' })
        break
      case 'multi-package':
        this.$store.commit('booking/setPreventFetchBooking', true)
        route.query.callback
          ? this.$router.push({
              path: route.query.callback,
              query: { load: false, from: route.query.from },
            })
          : this.$router.push({ name: 'BookingMultiPackage' })
        break
      default:
        break
    }
  }

  viewMaidDetail() {
    if (!this.disableClick) {
      this.detail.selected_time = this.selectedTime
      this.detail.selected_date = this.selectedDate
      this.setShowMaidModal(this.detail)
      this.$root.$emit('bv::show::modal', 'maid-modal') // fix me
      console.log('yest')
      // this.isLoad =false
    }
  }

  async toggleFavMaid() {
    this.updateStates({ isUpdatingFavMaid: true })
    if (this.isFavoriteMaid) {
      await this.unSaveToFavMaid(this.maidID)
      await this.addRecentlyUnFavMaids(this.detail)
    } else {
      await this.saveToFavMaid(this.maidID)
    }
    this.updateStates({ isUpdatingFavMaid: false })
  }

  getImg(link) {
    return link ? link : avatarDefault
  }
}
</script>

<style lang="scss" scoped>
/* override class */
.disable-view {
  cursor: not-allowed;
}
.card-maid {
  @media (min-width: 768px) {
    min-height: 334px;
  }
}
.card-footer {
  border: none;
  padding: 0;
}
.btn {
  border-radius: 0;
}
.fav-icon {
  position: absolute;
  top: 4px;
  right: 16px;
  color: #ff695b;
  font-size: 1.75em;
}
.friend_avatar {
  width: 36px !important;
  height: 36px !important;
  border-radius: 36px !important;
  margin-left: -8px;
}
.more-friend {
  z-index: 1;
  background-color: #fb6107;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  margin-left: -8px;
  color: white;
  text-align: center;
  vertical-align: middle;
  line-height: 35px;
}
i {
  color: #fff !important;
  cursor: pointer;
  &.text-red {
    color: #ff695b !important;
  }
}

.maid-desc {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  max-height: 62px;
}

.cover-image {
  width: 100%;
  height: 140px;
  object-fit: cover;
}
@media only screen and (min-width: 300px) {
  .vaccine {
    width: 90px !important;
    height: 20px;
  }
}
@media only screen and (min-width: 768px) {
  .vaccine {
    width: 64px !important;
    height: 20px;
  }
}

.limited {
  background-color: #f2f2f2;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 0px;
  margin-bottom: 5px;
}

.a_lot {
  background-color: #e5eeff;
  color: #2b79ff;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 0px;
  margin-bottom: 5px;
}

.vaccine {
  flex-shrink: 0;
  object-fit: contain !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}
</style>
